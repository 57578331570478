import { Directive, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { InputComponent } from '@util/components/input/input.component';
import { BaseFormComponent } from '../../base-form.component';
import { BaseValidatorDirective } from '../base-validator.directive';


@Directive({
  selector: '[appInputEmailValidator]',
  inputs: ['appInputEmailValidator.useTranslationKey', 'appInputEmailValidator.trigger'],
  exportAs: 'appInputEmailValidator',
  providers: [
    {provide: BaseValidatorDirective, useExisting: forwardRef(() => InputEmailValidatorDirective)},
    {provide: BaseFormComponent, useExisting: forwardRef(() => InputComponent)}
  ]
})
export class InputEmailValidatorDirective extends BaseValidatorDirective implements OnInit, OnDestroy {

  protected _thisSelector: string = 'appInputEmailValidator';
  protected override translationKey = 'validation.input.appInputEmailValidator';

  override ngOnInit() {
    super.ngOnInit();

    this.ngZone.run(() => {
      this.host?.formControl?.addValidators(this.validator);

      this.host?.formControl?.updateValueAndValidity();
      this.host?.formControl?.markAsPristine();
      this.host?.formControl?.markAsUntouched();
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  validator: ValidatorFn = (control) => {
    // angular validator is not strict enough
    // const wrappedErrors = Validators.email(control);

    const val = control?.value || '';
    const regexp = /.{1,}@.{1,}[\.].{2,}/;
    const valid = val.length === 0 || regexp.test(val);

    const errors: ValidationErrors = {'appInputEmailValidator': this.translation};
    return valid ? null : errors;
  };

}
